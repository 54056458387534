$brand-color: rgb(17, 138, 152);

* {
  box-sizing: border-box;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.hide {
  display: none;
}

.home-page-logo {
  margin-bottom: 40px;
  text-align: center;
  border-bottom: 2px solid #eee;

  img {
    max-width: 100%;
    height: auto;
  }
}

.home-page-flash-notice {
  padding: 20px;
  font-size: 26px;
  border: 2px solid red;
  border-radius: 4px;
}

.home-page-title {
  text-align: center;
  color: $brand-color;
}


.home-page-description {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 26px;
  color: #666
}

.home-page-donation-form {
  max-width: 600px;
  margin: 0 auto 40px;
  padding: 20px;
  border: 2px solid $brand-color;
  border-radius: 4px;


  &__error {
    font-style: italic;
    color: red;
    border-bottom: 2px solid red;
  }

  &__input-group {
    padding: 10px 0;

    label {
      display: inline-block;
      margin-bottom: 4px;
      color: $brand-color;
      font-weight: bold;
    }

    input {
      display: block;
      width: 100%;
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      border: 1px solid #dee2e6;
      border-radius: 2px;
    }

    &__error {
      font-style: italic;
      color: red;
    }

    &__note {
      font-size: .75em;
      color: rgba(33, 37, 41, 0.75);
    }
  }

  &__note {
    padding: 20px 0;
    font-size: .75em;
    text-align: center;

    a {
      display: block;
      color: $brand-color;
    }

    a + a {
      margin-top: 10px;
    }
  }

  &__submit-group {
    text-align: center;

    input {
      display: inline-block;
      width: 100%;
      margin: .375em 0;
      padding: .375rem .75rem;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.5;
      color: white;
      background-color: $brand-color;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      border: none;
      border-radius: 4px;
    }

    &__note {
      font-size: 75%;
      color: rgba(33, 37, 41, 0.75);
    }
  }
}

.home-page-partners {
  padding: 20px;
  text-align: center;
  border-top: 2px solid #eee;
}

.home-page-partners__item {
  padding: 10px;
}

